<template>
  <section class="example">
    <img src="../assets/phone.png" alt="Example" />
    <!-- <lottie-player src="../assets/jokerlottie.json" background="transparent" speed="1" style="width: 320px; height: 370px;" loop autoplay></lottie-player> -->
  </section>
</template>

<script>
export default {
  name: 'ExampleImage'
}
</script>

<style scoped>
.example {
  margin: auto;
  max-width: 90%; /* 增加最大宽度 */
  text-align: center; /* 居中内容 */
}

img {
  max-width: 100%; /* 确保图片不超过父容器 */
  height: auto; /* 保持图片比例 */
}

@media screen and (orientation: landscape) and (min-width: 900px) {
  .example {
    width: 35%; /* 减小宽度 */
    min-width: 300px; /* 减小最小宽度 */
    position: absolute;
    top: 10%; /* 稍微向下移动 */
    right: 25%; /* 将右边缘移到屏幕中间 */
    transform: translateX(50%); /* 向左移动自身宽度的一半，实现居中 */
  }
}

/* 添加一个新的媒体查询，针对小屏幕设备 */
@media screen and (max-width: 899px) {
  .example {
    max-width: 95%; /* 在小屏幕上进一步增加最大宽度 */
    margin-top: 2rem; /* 增加顶部边距 */
    margin-bottom: 2rem; /* 增加底部边距 */
  }
}
</style>