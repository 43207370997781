<template>
  <div class="download-buttons">
    <button class="download-ios" @click="handleDownload('IOS')" aria-label="Download for iOS"></button>
    <button class="download-android" @click="handleDownload('GP')" aria-label="Download for Android"></button>
  </div>
</template>

<script>
export default {
  name: 'DownloadButtons',
  data() {
    return {
      platformStatus: {
        IOS: true,    // iOS app is launched
        GP: false     // Android app is not launched yet
      }
    }
  },
  methods: {
    handleDownload(platform) {
      if (!this.platformStatus[platform]) {
        alert('Coming soon!');
        return;
      }

      const url = platform === 'GP' 
        ? 'https://play.google.com/store/apps/details?id=com.widgetable.theme.android&referrer=utm_source%3Dwebsite_gp'
        : 'https://apps.apple.com/us/app/popwatch-faces/id6737128940';
      
      window.location.assign(url);
    }
  }
}
</script>

<style scoped>
.download-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-ios {
  background: url('../assets/download-ios.png') no-repeat center/contain;
}

.download-android {
  background: url('../assets/google-play.png') no-repeat center/contain;
}

.download-ios, .download-android {
  width: 11rem;
  height: 3.2rem;
  margin: 1em auto;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

@media screen and (orientation: landscape) and (min-width: 900px) {
  .download-buttons {
    flex-direction: row;
    justify-content: flex-start;
  }

  .download-ios, .download-android {
    width: 230px;
    height: 68px;
    position: absolute;
    top: 68%;
    margin: 0;
  }

  .download-ios {
    left: 90px;
  }

  .download-android {
    left: 350px;
  }
}
</style>